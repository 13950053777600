<template>
  <div>
    <b-sidebar id="sidebar-collaborateur" ref="sideBarCollaborateur" backdrop bg-variant="white" no-header right shadow sidebar-class="sidebar-lg">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder"><span v-if="isUpdate"> Modifier l'interlocuteur</span> <span v-else>Nouvel interlocuteur</span></h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="collaborateurComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <b-form-group>
              <b-form-checkbox v-model="composeData.courtierUser.isNonUtilisateur" :disabled="is_interlocuteur" > <span v-if="isUpdate"> Interlocuteur sans accès à BubbleIn </span> <span v-else>  Ne pas créer d'accès à BubbleIn pour cet interlocuteur </span> </b-form-checkbox>
            </b-form-group>
            <h4 class="text-uppercase">Identité</h4>
            <div>
              <b-form-group label="Civilité *" label-for="civilite">
                <validation-provider #default="{ errors }" name="civilité" rules="required">
                  <b-form-select v-model="composeData.personnePhysique.civilite" :options="civiliteOptions" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nom *" label-for="nom">
                <validation-provider #default="{ errors }" name="nom" rules="required">
                  <b-form-input class="text-uppercase" id="nom" v-model="composeData.personnePhysique.nom" :state="errors.length > 0 ? false : null" placeholder="Nom" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Prénom *" label-for="prenom">
                <validation-provider #default="{ errors }" name="prénom" rules="required">
                  <b-form-input class="text-capitalize" id="prenom" v-model="composeData.personnePhysique.prenom" :state="errors.length > 0 ? false : null" placeholder="Prénom" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nom d'utilisateur *" label-for="user-name" v-if="!composeData.courtierUser.isNonUtilisateur">
                <validation-provider #default="{ errors }" name="nom d'utilisateur" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : ''">
                    <b-form-input id="user-name" v-model="composeData.user.name" :state="errors.length > 0 ? false : null" disabled placeholder="Nom d'utilisateur" />
                    <!-- <b-input-group-append is-text>
                      <feather-icon icon="RefreshCwIcon" class="cursor-pointer" @click="toggleGenerateUserName" />
                    </b-input-group-append> -->
                  </b-input-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="isUpdate && !composeData.courtierUser.isNonUtilisateur" label="Voulez-vous changer le mot de passe?">
                <b-form-radio-group v-model="composeData.isChangePassword" :options="optionsInterlocuteur" name="radio-inline-change-password" />
              </b-form-group>
              <b-form-group v-if="composeData.isChangePassword && !composeData.courtierUser.isNonUtilisateur" label="Mot de passe *" label-for="password">
                <validation-provider #default="{ errors }" :rules="composeData.isChangePassword ? 'required|password|min:12' : ''" name="mot de passe" vid="Password">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : ''">
                    <b-form-input id="basic-password" v-model="composeData.user.password" :state="errors.length > 0 ? false : null" :type="passwordFieldType" placeholder="Mot de passe" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="composeData.isChangePassword && !composeData.courtierUser.isNonUtilisateur" label="Confirmation de mot de passe *" label-for="confirm-password">
                <validation-provider #default="{ errors }" :rules="composeData.isChangePassword ? 'required|confirmed:Password|min:12' : ''" name="confirmation de mot de passe">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : ''">
                    <b-form-input id="confirm-password" v-model="composeData.user.confirm_password" :state="errors.length > 0 ? false : null" :type="passwordCon" placeholder="Confirmation de mot de passe" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIconCon" class="cursor-pointer" @click="togglePasswordVisibilityCon" />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback v-if="errors[0] == 'confirmation de mot de passe n\'est pas identique'" :state="errors.length > 0 ? false : null">Le mot de passe n'est pas identique </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-else :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Date de naissance " label-for="date-naissance">
                <validation-provider #default="{ errors }" name="date de naissance">
                  <flat-pickr id="date-naissance" v-model="composeData.personnePhysique.date_naissance" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDateNaisse" class="form-control" placeholder="Date de naissance" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              
              <b-form-group label="Statut juridique" label-for="statut_juridique">
                <validation-provider #default="{ errors }" name="Statut juridique">
                <b-form-select :disabled="is_interlocuteur" id="statut_juridique"  v-model="composeData.personnePhysique.statut_juridique" :options="statutJuridiqueOptions"/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <b-alert show variant="warning">
                <div class="alert-body">
                  <span class="text-warning"> La sélection d’un statut juridique entraine la modification des droits de l’utilisateur. Vous pourrez ensuite personnaliser ses droits </span>
                </div>
              </b-alert>

            

            </div>

            <h4 class="text-uppercase">Contact</h4>
            <div>
              <b-form-group label="Numéro de téléphone " label-for="phone">
                <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="composeData.moyenContact.indicatif_tel" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                <div class="w-100 bd-highlight">
                <validation-provider #default="{ errors }" :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }" name="numéro de téléphone">
                  <cleave id="phone"
                            v-model="composeData.moyenContact.tel"
                            :class="errors.length > 0 ? 'is-invalid' : ''" 
                            :options="options.phone" 
                            :raw="false" 
                            class="form-control" 
                            style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"                          
                            placeholder="ex: 06 00 00 00 00" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null" :style="errors.length > 0 ? 'margin-left:-71px' : '' ">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
                 </div>
                </div>
              </b-form-group>
              <b-form-group label="E-mail *" label-for="email">
                <validation-provider #default="{ errors }" name="e-mail" rules="required|email">
                  <b-form-input v-model="composeData.moyenContact.email" :state="errors.length > 0 ? false : null" placeholder="example@example.com" type="email" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </div>

            <h4 class="text-uppercase">Coordonnées postales</h4>
            <div>
              <b-form-group label="N° et libellé de la voie " label-for="nEtLibelleVoie">
                <validation-provider #default="{ errors }" name="N° et libellé de la voie">
                  <b-form-input id="nEtLibelleVoie" v-model="composeData.moyenContact.adresse" :state="errors.length > 0 ? false : null" placeholder="N° et libellé de la voie" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)" label-for="complementAdresse">
                <b-form-input id="complementAdresse" v-model="composeData.moyenContact.complement_adresse" placeholder="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)" />
              </b-form-group>

              <b-form-group label="Lieu-dit ou BP" label-for="lieuDitOuBp">
                <!-- <validation-provider #default="{ errors }" name="lieu-dit ou BP" rules="required"> -->
                <b-form-input id="lieuDitOuBp" v-model="composeData.moyenContact.lieu_dit_ou_bp" placeholder="Lieu-dit ou BP" />
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Code postal " label-for="codePostal">
                <validation-provider #default="{ errors }" name="code postal">
                  <b-form-input id="codePostal" v-model="composeData.moyenContact.code_postal" :state="errors.length > 0 ? false : null" placeholder="Code postal" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Ville " label-for="ville">
                <validation-provider #default="{ errors }" name="ville">
                  <b-form-select id="ville" v-model="composeData.moyenContact.ville_id" :options="villeOptions" :state="errors.length > 0 ? false : null" text-field="ville" value-field="id">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </div>

            <h4 class="text-uppercase">Entrée et sortie</h4>
            <div>
              <b-form-group label="Courtier" label-for="courtier">
                <b-form-input id="courtier" v-model="denominationCommercial" disabled placeholder="Courtier" />
              </b-form-group>

              <b-form-group label="Date d'entrée " label-for="date-entree">
                <validation-provider #default="{ errors }" name="date d'entrée">
                  <flat-pickr id="date-entree" v-model="composeData.qualification.date_entree" :class="isDatesError || errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="date d'entrée" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Date de sortie" label-for="date-sortie">
                <flat-pickr id="date-sortie" v-model="composeData.qualification.date_sortie" :class="isDatesError ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="date de sortie" />
                <b-form-invalid-feedback :state="isDatesError ? false : null"> Date de sortie doit être après la date d'entrée </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <h4 class="text-uppercase">Fonctions</h4>
            <div>
              <b-form-group label="Fonction" label-for="fonction">
                <!-- <validation-provider #default="{ errors }" name="fonction" rules="required"> -->
                <b-form-select id="fonction" v-model="composeData.qualification.fonction" :options="fonctionOptions" />
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider> -->
              </b-form-group>
              <b-form-group label="Depuis le" label-for="fonction-depuis-le">
                <!-- <validation-provider #default="{ errors }" name="depuis le" rules="required"> -->
                <flat-pickr id="fonction-depuis-le" v-model="composeData.qualification.depuis_le" :config="configDate" class="form-control" placeholder="depuis le" />
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider> -->
              </b-form-group>
            </div>

            <!-- <h4 class="text-uppercase" v-if="!composeData.courtierUser.isNonUtilisateur">Interlocuteur</h4> -->
            <p v-if="!composeData.courtierUser.isNonUtilisateur">En faire l’interlocuteur administratif du cabinet ?</p>
            <div v-if="!composeData.courtierUser.isNonUtilisateur">
              <b-form-group>
                <b-form-radio-group v-model="composeData.courtierUser.is_interlocuteur" :disabled="is_interlocuteur" :options="optionsInterlocuteur" name="radio-inline-interlocuteur-a" />
              </b-form-group>
            </div>
            <p>Est-il le DPO du cabinet ?</p>
            <div>
              <b-form-group>
                <b-form-radio-group v-model="composeData.courtierUser.dpo" :options="optionsDPO" name="radio-inline-dpo-a" />
              </b-form-group>
            </div>
            <p>Est-il le correspondant TRACFIN du cabinet ?</p>
            <div>
              <b-form-group>
                <b-form-radio-group v-model="composeData.courtierUser.correspondant_tracfin" :options="optionsCorrespondantTracfin" name="radio-inline-correspondant_tracfin-a" />
              </b-form-group>
            </div>
            <p>Est-il le déclarant TRACFIN du cabinet ?</p>
            <div>
              <b-form-group>
                <b-form-radio-group v-model="composeData.courtierUser.declarant_tracfin" :options="optionsDeclarantTtracfin" name="radio-inline-declarant_tracfin-a" />
              </b-form-group>
            </div>

            <div v-if="false">
              <h4 class="text-uppercase">Salaire</h4>
              <div>
                <b-form-group label="Salaire fixe" label-for="salaire-fixe">
                  <!-- <validation-provider #default="{ errors }" name="salaire fixe" rules="required"> -->
                  <cleave id="salaire-fixe" v-model.trim="composeData.qualification.salaire_fixe" :options="options.number" :raw="false" class="form-control" placeholder="10 000,00" />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider> -->
                </b-form-group>

                <b-form-group label="Salaire variable" label-for="salaire-variable">
                  <!-- <validation-provider #default="{ errors }" name="salaire variable" rules="required"> -->
                  <cleave id="salaire-variable" v-model.trim="composeData.qualification.salaire_variable" :options="options.number" :raw="false" class="form-control" placeholder="10 000,00" />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider> -->
                </b-form-group>

                <b-form-group label="Depuis le *" label-for="salaire-depuis-le">
                  <!-- <validation-provider #default="{ errors }" name="depuis le" rules="required"> -->
                  <flat-pickr id="salaire-depuis-le" v-model="composeData.qualification.salaire_depuis_le" :config="configDate" class="form-control" placeholder="depuis le" />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                      errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
                </b-form-group>

                <h4 class="text-uppercase">Qualification initiale</h4>

                <b-form-group label="Qualification de *" label-for="qualification-de">
                  <validation-provider #default="{ errors }" name="qualification de niveau" rules="required">
                    <b-form-select v-model="composeData.qualification.niveau" :options="qualificationNiveauOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary" @click="recordCollaborateur"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BFormCheckbox, BAlert, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormSelect, BFormSelectOption, BInputGroup, BInputGroupAppend, BOverlay, BSidebar, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'


export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BFormSelectOption,
    BInputGroup,
    BFormInvalidFeedback,
    BFormRadioGroup,
    Cleave,
    BFormSelect,
    flatPickr,
    BFormCheckbox,
    BOverlay,
    BAlert,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [togglePasswordVisibility],
  props: {
    dataSelectedForEdit: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        userId: null,
        courtierId: null,
        qualificationId: null
      },
      required: false
    },
    denominationCommercial: {
      type: String,
      default: null,
      required: true
    },
    isUpdate: {
      type: Boolean,
      default: true,
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: true
    }
  },
  data() {
    return {
      required,
      indicatif:[],
      is_interlocuteur: false,
      declarant_tracfin: false,
      isNonUtilisateur: false,
      dpo: false,
      correspondant_tracfin: false,
      showLoading: false,
      isDatesError: false,
      isUpdateAction: false,
      villeOptions: [],
      passwordCon: 'password',
      qualificationNiveauOptions: [
        {
          text: '-- Choisissez --',
          value: null
        },
        {
          text: 'niveau 1',
          value: 'niveau 1'
        },
        {
          text: 'niveau 2',
          value: 'niveau 2'
        },
        {
          text: 'niveau 3',
          value: 'niveau 3'
        }
      ],
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      optionsInterlocuteur: [
        {
          text: 'Non',
          value: false
        },
        {
          text: 'Oui',
          value: true
        }
      ],
      optionsDPO: [
        {
          text: 'Non',
          value: false
        },
        {
          text: 'Oui',
          value: true
        }
      ],
      optionsCorrespondantTracfin: [
        {
          text: 'Non',
          value: false
        },
        {
          text: 'Oui',
          value: true
        }
      ],
      optionsDeclarantTtracfin: [
        {
          text: 'Non',
          value: false
        },
        {
          text: 'Oui',
          value: true
        }
      ],
      civiliteOptions: [
        {
          text: '-- Choisissez --',
          value: null
        },
        {
          text: 'M.',
          value: 'MR'
        },
        {
          text: 'Mme',
          value: 'MME'
        },
        {
          text: 'Autre',
          value: 'AUTRE'
        }
      ],
      fonctionOptions: [
        {
          value: null,
          text: '-- Choisissez --'
        },
        {
          value: 'Administratif',
          text: 'Administratif'
        },
        {
          value: 'Commercial',
          text: 'Commercial'
        },
        {
          value: 'Comptable',
          text: 'Comptable'
        },
        {
          value: 'Gestionnaire',
          text: 'Gestionnaire'
        },
        {
          value: 'IT',
          text: 'IT'
        },
        {
          value: 'Responsable',
          text: 'Responsable'
        },
        {
          value: 'Responsable Commercial',
          text: 'Responsable Commercial'
        }
      ],
      statutJuridiqueOptions: [
        {
          value: null,
          text: '-- Choisissez --'
        },
        {
          value: 'Salarié',
          text: 'Salarié'
        },
        {
          value: 'Mandataire',
          text: 'Mandataire'
        },
        {
          value: 'Co-courtier',
          text: 'Co-courtier'
        },
        {
          value: 'Indicateur',
          text: 'Indicateur'
        },
        {
          value: 'Associé',
          text: 'Associé'
        }
      ],
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      configDateNaisse: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        disable: [
          {
            from: moment(new Date(), 'DD/MM/YYYY')._d,
            to: '25/02/3000'
          }
        ],
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      composeData: {
        user: {
          name: null,
          email: null,
          password: null,
          confirm_password: null,
          active: 1,
          type_utilisateur: 'courtier',
          sous_domaine: 'courtier'
        },
        courtierUser: {
          courtier_id: this.courtierId,
          user_id: null,
          responsable_user_fk: null,
          is_interlocuteur: false,
          dpo: false,
          correspondant_tracfin: false,
          declarant_tracfin: false,
          statut_juridique:null,
          isNonUtilisateur: false,
          changeNonUtilisateur: false,
        },
        personnePhysique: {
          type: 'COLLABORATEUR',
          categorie: 'INTERLOCUTEUR',
          civilite: null,
          nom: null,
          prenom: null,
          date_naissance: null,
          statut_fiche: 'Utilisateur',
          user_id: null,
          statut_juridique : null
        },
        moyenContact: {
          tel: null,
          email: null,
          adresse: null,
          indicatif_tel:null,
          lieu_dit_ou_bp: null,
          complement_adresse: null,
          code_postal: null,
          ville_id: null,
          personne_physique_id: null
        },
        qualification: {
          courtier_fk: this.courtierId,
          user_id: null,
          date_entree: null,
          date_sortie: null,
          fonction: null,
          depuis_le: null,
          salaire_fixe: null,
          salaire_variable: null,
          salaire_depuis_le: null,
          niveau: null
        },
        idQualification: null,
        idMoyenContact: null,
        idPersonnePhysique: null,
        idCourtierUser: null,
        idUser: null,
        courtierId: this.courtierId,
        isChangePassword: true
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconCon() {
      return this.passwordCon === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  watch: {
    'composeData.courtierUser.isNonUtilisateur': {
      handler(val) {
        if (val) {
          const newP = this.generatePassword()
          this.composeData.isChangePassword = false
          this.composeData.courtierUser.is_interlocuteur = false
          this.composeData.user.password = newP
          this.composeData.user.confirm_password = newP
        } else {
          this.composeData.isChangePassword = true
          this.composeData.courtierUser.is_interlocuteur = false
        }
      }
    },
    'composeData.isChangePassword': {
      handler() {
        this.composeData.user.password = null
        this.composeData.user.confirm_password = null
      }
    },
    'composeData.personnePhysique.prenom': {
      handler(val) {
        if (val && this.composeData.personnePhysique.nom && !this.isUpdate) this.countIfHasSameName()
      }
    },
    'composeData.personnePhysique.nom': {
      handler(val) {
        if (val && this.composeData.personnePhysique.prenom && !this.isUpdate) this.countIfHasSameName()
      }
    },
    'composeData.personnePhysique.statut_juridique': {
      handler(val) {
        console.log(val)
        this.composeData.courtierUser.statut_juridique = val
      }
    },
    isUpdate: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          if (this.dataSelectedForEdit) {
            this.fetchCollaborateurByCourtierAndId(this.dataSelectedForEdit)
          }

          // this.clearDataSideBar()
        } else {
          this.clearDataSideBar()
        }
      }
    },
    dataSelectedForEdit: {
      deep: true,
      handler(val) {
        if (this.isUpdate) {
          if (val) {
            this.fetchCollaborateurByCourtierAndId(val)
          }
        } else {
          this.clearDataSideBar()
        }
      }
    },
    'composeData.moyenContact.code_postal': {
      immediate: true,
      handler(val) {
        this.GetVille(val)
      }
    },
    'composeData.moyenContact.email': {
      handler(val) {
        this.composeData.user.email = val
      }
    },
    'composeData.qualification.date_entree': {
      handler() {
        this.isDatesError = this.composeData.qualification.date_sortie ? this.datesChecker(this.composeData.qualification.date_entree, this.composeData.qualification.date_sortie) : false
      }
    },
    'composeData.qualification.date_sortie': {
      handler() {
        this.isDatesError = this.composeData.qualification.date_sortie ? this.datesChecker(this.composeData.qualification.date_entree, this.composeData.qualification.date_sortie) : false
      }
    }
  },
  created(){
    this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        let indexFrance = r.data.indexOf(france)
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
        this.composeData.moyenContact.indicatif_tel = this.indicatif[0].indicatif
      })
      .catch((e)=>{
        console.log(e);
      })
  },
  methods: {
    datesChecker(dateDebut, DateFin) {
      if (moment(dateDebut, 'DD/MM/YYYY').diff(moment(DateFin, 'DD/MM/YYYY')) > 0) {
        return true
      }
      return false
    },
    fetchCollaborateurByCourtierAndId(dataSelectedForEdit) {
      this.showLoading = true
      this.clearDataSideBar()
      this.$http
        .post('/collaborateur/fetchCollaborateurByCourtierAndId', {
          courtierId: dataSelectedForEdit.courtierId,
          idQualification: dataSelectedForEdit.qualificationId,
          userId: dataSelectedForEdit.userId
        })
        .then(res => {
          if (res.data.success) {
            this.composeData = res.data.data
            this.is_interlocuteur = this.composeData.courtierUser.is_interlocuteur
            this.declarant_tracfin = this.composeData.courtierUser.declarant_tracfin
            this.dpo = this.composeData.courtierUser.dpo
            this.isNonUtilisateur = this.composeData.courtierUser.isNonUtilisateur
            if (this.composeData.courtierUser.isNonUtilisateur) {
              this.composeData.courtierUser.changeNonUtilisateur = true
            }
            this.correspondant_tracfin = this.composeData.courtierUser.correspondant_tracfin
            this.composeData.courtierUser.statut_juridique = this.composeData.personnePhysique.statut_juridique
            this.isUpdate = true
            this.showLoading = false
          } else {
            this.showLoading = false
            this.messageToast("Nous n'avons pas pu trouver la ressource que vous avez demandée.", 'Erreur', 'warning')
          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    countIfHasSameName() {
      const userName = this.composeData.personnePhysique.prenom.charAt(0) + this.composeData.personnePhysique.nom
      this.$http
        .get(`/collaborateur/countIfHasSameName/${userName}`)
        .then(res => {
          if (res.data.success) {
            if (res.data.data > 0) {
              this.composeData.user.name = (userName + res.data.data).toLowerCase()
            } else {
              this.composeData.user.name = userName.toLowerCase()
            }
          } else {
            this.composeData.user.name = null
            this.messageToast("Nous n'avons pas pu trouver la ressource que vous avez demandée.", 'Erreur', 'warning')
          }
        })
        .catch(err => {
          this.composeData.user.name = null
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    hideSideBare() {
      this.$refs.sideBarCollaborateur.hide()
    },
    // Methode additional
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
          .get(`/helpers/${code}/villes`)
          .then(res => {
            if (res.data?.length > 0) {
              this.villeOptions = [...res.data]
              this.composeData.moyenContact.ville_id = this.villeOptions[0].id
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.villeOptions = []
      }
    },
    toggleGenerateUserName() {
      if (this.composeData.personnePhysique.prenom != null && this.composeData.personnePhysique.nom != null) {
        this.composeData.user.name = this.composeData.personnePhysique.prenom + this.composeData.personnePhysique.nom
      }
    },
    clearDataSideBar() {
      this.composeData.user.name = null
      this.composeData.user.email = null
      this.composeData.user.password = null
      this.composeData.user.confirm_password = null
      this.composeData.user.active = 1
      this.composeData.user.type_utilisateur = 'courtier'
      this.composeData.user.sous_domaine = 'courtier'
      this.composeData.courtierUser.courtier_id = this.courtierId
      this.composeData.courtierUser.user_id = null
      this.composeData.courtierUser.responsable_user_fk = null
      this.composeData.courtierUser.is_interlocuteur = false
      this.is_interlocuteur = false
      this.dpo = false
      this.correspondant_tracfin = false
      this.declarant_tracfin = false
      this.isNonUtilisateur = false
      this.composeData.courtierUser.dpo = false
      this.composeData.courtierUser.correspondant_tracfin = false
      this.composeData.courtierUser.declarant_tracfin = false
      this.composeData.courtierUser.isNonUtilisateur = false
      this.composeData.courtierUser.statut_juridique = null


      this.composeData.personnePhysique.type = 'COLLABORATEUR'
      this.composeData.personnePhysique.categorie = 'INTERLOCUTEUR'
      this.composeData.personnePhysique.civilite = null
      this.composeData.personnePhysique.nom = null
      this.composeData.personnePhysique.prenom = null
      this.composeData.personnePhysique.date_naissance = null
      this.composeData.personnePhysique.statut_fiche = 'Utilisateur'
      this.composeData.personnePhysique.user_id = null
      this.composeData.personnePhysique.statut_juridique = null

      this.composeData.moyenContact.tel = null
      this.composeData.moyenContact.indicatif_tel = null
      this.composeData.moyenContact.email = null
      this.composeData.moyenContact.adresse = null
      this.composeData.moyenContact.lieu_dit_ou_bp = null
      this.composeData.moyenContact.complement_adresse = null
      this.composeData.moyenContact.code_postal = null
      this.composeData.moyenContact.ville_id = null
      this.composeData.moyenContact.personne_physique_id = null

      this.composeData.qualification.courtier_fk = this.courtierId
      this.composeData.qualification.user_id = null
      this.composeData.qualification.date_entree = null
      this.composeData.qualification.date_sortie = null
      this.composeData.qualification.fonction = null
      this.composeData.qualification.depuis_le = null
      this.composeData.qualification.salaire_fixe = null
      this.composeData.qualification.salaire_variable = null
      this.composeData.qualification.salaire_depuis_le = null
      this.composeData.qualification.niveau = null

      this.composeData.idQualification = null
      this.composeData.idMoyenContact = null
      this.composeData.idPersonnePhysique = null
      this.composeData.idCourtierUser = null
      this.composeData.idUser = null
      this.composeData.courtierId = this.courtierId
      this.composeData.isChangePassword = true
      this.$refs.collaborateurComposeRules.reset()
    },

    // record Data collaborateur
    requestCollaborateur() {
      console.log(this.composeData)
      if (this.composeData.courtierUser.changeNonUtilisateur && this.composeData.courtierUser.isNonUtilisateur) {
        this.composeData.courtierUser.changeNonUtilisateur = false
      } 
      this.showLoading = true
      this.$http
        .post('/collaborateur/recordCollaborateur', this.composeData)
        .then(res => {
          if (res.data.success) {
            this.showLoading = false
            this.$emit('record-collaborateur-returned', res.data.data[0], res.data.message)
            this.clearDataSideBar()
            this.hideSideBare()
          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', 'warning')
          // console.error(err.response.data.errors[0])
        })
    },
    recordCollaborateur() {
      if (this.isDatesError) {
        return 0
      }
      this.$refs.collaborateurComposeRules.validate().then(success => {
        if (success) {
          const qstIsInterlocuteur = this.composeData.courtierUser.is_interlocuteur && !this.is_interlocuteur ? '<li class="mb-1"><span>En faire l\'interlocuteur administratif du cabinet ?</span></li>' : ''

          const qsPermission = []

          if (this.composeData.courtierUser.dpo && !this.dpo) qsPermission.push('le DPO')
          if (this.composeData.courtierUser.correspondant_tracfin && !this.correspondant_tracfin) qsPermission.push('le Correspondant TRACFIN')
          if (this.composeData.courtierUser.declarant_tracfin && !this.declarant_tracfin) qsPermission.push('le Déclarant Tracfin')

          let htmlText = '<p class="text-left">Êtes-vous certain(e) de vouloir :</p>'
          htmlText += '<ul class="text-left list-style-circle pl-2">'
          htmlText += qstIsInterlocuteur
          if (qsPermission.length > 0) htmlText += '<li class="mb-1">Définir <strong>' + this.formatUserName(this.composeData.personnePhysique.prenom, this.composeData.personnePhysique.nom) + '</strong> ' + qsPermission.reduce((text, value, i, qsPermission) => text + (i < qsPermission.length - 1 ? ', ' : ' et ') + value) + ' ?</li>'
          htmlText += '</ul>'

          if (qstIsInterlocuteur || qsPermission.length > 0) {
            this.$swal({
              html: htmlText,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
            }).then(result => {
              if (result.value) {
                this.requestCollaborateur()
              }
              //   else {
              //   this.composeData.courtierUser.is_interlocuteur = false
              // }
            })
          } else {
            this.requestCollaborateur()
          }
        }
      })
    },
    togglePasswordVisibilityCon() {
      this.passwordCon = this.passwordCon === 'password' ? 'text' : 'password'
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
input#indicatif.vs__search{
  padding: 0px;
}
.vs__selected-options{
  height: 31px;
}
.indicatif-chooser .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}
.v-select.indicatif-chooser.vs--open.vs--single.vs--searchable
{
  min-width: 20%;
  width:20%;
}
.vs__dropdown-toggle{
  width:111%
}
</style>
